<template>
  <div>
    <!--    <div style="margin:10px 0">-->
    <!--      <a-input placeholder="回车搜索" @keydown.enter="search" v-model="sValue"/>-->
    <!--    </div>-->
    <div style="margin: 15px 0">
      <a-input-search v-model="sValue" placeholder="回车搜索" @search="search" @keydown.enter="search"
                      style="width: 20%"/>
    </div>
    <div>
      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
          :pagination="false"
      >
      </a-table>
    </div>

    <a-pagination style="float: right;margin-top: 15px;"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />


  </div>
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      curr: 1,
      currSearch: 1,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",

        },
        {
          title: '订单号',
          dataIndex: 'oid',
          key: 'oid',
          align: "center",
        },

        {
          title: '用户ID',
          dataIndex: 'uid',
          key: 'uid',
          align: "center",

        },
        {
          title: '商品ID',
          dataIndex: 'gid',
          key: 'gid',
          align: "center",

        },
        {
          title: '单价',
          dataIndex: 'price',
          key: 'price',
          align: "center",

        },
        {
          title: '点数',
          dataIndex: 'score',
          key: 'score',
          align: "center",
        },

        {
          title: '支付方式',
          dataIndex: 'pay_method',
          key: 'pay_method',
          align: "center",
        },
        {
          title: '支付状态',
          dataIndex: 'payment_status',
          key: 'payment_status',
          align: "center",
        },

        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
        },


      ],
      data: [],
      returnBack: [],
      sValue: ''
    }
  },
  methods: {
    search() {
      this.$axios.post('/Admin/Api/Order/searchOrder', {
        oid: this.sValue
      }).then(v => {
        this.$message.success(v.data.msg)
        this.data = []
        this.data.push(v.data.data)
      })
    }
  },
  watch: {
    curr: {
      async handler(n) {
        this.data = []
        this.returnBack = (await this.$axios.post('/Admin/Api/Order/getOrderList', {
          "page": n,
          "pageCount": 12
        })).data.data
        this.returnBack.list.forEach(v => {
          this.data.push({
            ...v,
            pay_method: v.pay_method != 0 ? '支付宝' : '微信',
            payment_status: v.payment_status != 0 ? '支付成功' : '未支付'
          })
        })
      }
    },
  },
  async created() {
    this.returnBack = (await this.$axios.post('/Admin/Api/Order/getOrderList', {
      "page": 1,
      "pageCount": 12
    })).data.data
    this.returnBack.list.forEach(v => {
      this.data.push({
        ...v,
        pay_method: v.pay_method != 0 ? '支付宝' : '微信',
        payment_status: v.payment_status != 0 ? '支付成功' : '未支付'
      })
    })
  }
}
</script>

<style scoped>

</style>